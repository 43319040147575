import {Head, useForm, usePage} from '@inertiajs/react';
import InputError from "@/Components/InputError";
import { pageBaseAddress } from '@/stores/GlobalData';
import { useWebApp } from "@vkruglikov/react-telegram-web-app";
import React, { useEffect, useState } from "react";
import trunkImage from '../../images/trunk.png';
import '../../css/main-game.css';
import '../../css/app.css';
import DOMPurify from 'dompurify';
import Loading from "@/Components/Game/GeneralComponents/Loading";
import { router } from '@inertiajs/react'

export default function Landing({ CSRFToken }) {
    const { error } = usePage().props

    const [telegramLoaded, setTelegramLoaded] = useState(false);
    const [bgColor, setBgColor] = useState('#ffffff');
    const [textColor, setTextColor] = useState('#000000');
    const [user, setUser] = useState(null);

    const { post, processing } = useForm({});
    const isWindowLoaded = useWindowLoaded();
    const telegramWebApp = useWebApp();

    useEffect(() => {
        if (!telegramWebApp) return;
        setTelegramLoaded(true);
        setBgColor(telegramWebApp.backgroundColor);
        setTextColor(telegramWebApp.themeParams.text_color);
        setUser(telegramWebApp.initDataUnsafe.user);
    }, [telegramWebApp]);

    useEffect(() => {
        if (telegramLoaded && isWindowLoaded) {
            setTimeout(() => {
                submit();
            }, 1000);
        }
    }, [telegramLoaded, isWindowLoaded]);

    const submit = () => {
        //@ts-ignore
        gtag("event", 'page_view', { 'page_location': pageBaseAddress + 'register', 'page_title': 'register' });
        const data={
            initData: telegramWebApp.initData,
            initDataUnsafe: JSON.stringify(telegramWebApp.initDataUnsafe)
        }

        if (telegramWebApp) {
            router.post(route('telegram.register'),data)

        }
    };

    return (
        <div className="min-h-screen flex flex-col sm:justify-center items-center pt-6 sm:pt-0" style={{ backgroundColor: bgColor }}>
            <div className="max-w-md min-w-[300px]  rounded-lg mx-[5px]">
                <Head>
                    <title>Chroma cubs</title>
                </Head>

                <div className={'w-[1px] h-[1px]'}>
                    <div className={'gp-branch-color-sign'} />
                    <div key='trunk' className="trunk absolute w-0 h-0 right-0 top-0 bg-repeat-y z-[2]"
                         style={{ backgroundImage: `url(${trunkImage})` }}></div>
                </div>
                <div className="flex items-center justify-center mt-4 flex-col" style={{ color: textColor }}>
                    <div className={'gp-login w-[219px] h-[219px] mb-[20px]'} />
                    {telegramLoaded && user && <div>Welcome, {DOMPurify.sanitize(user.first_name)}</div>}
                    <div style={{ color: textColor }}>We are loading your forrest...</div>
                    {!error &&
                    <div className={'mt-[20px]'}>
                        <Loading />
                    </div>
                    }

                    <div className="flex items-center justify-center mt-4">
                        <InputError message={error} className="mt-2"/>
                    </div>
                </div>
            </div>
        </div>
    );
}

const useWindowLoaded = () => {
    const [isWindowLoaded, setIsWindowLoaded] = useState(false);

    useEffect(() => {
        const handleLoad = () => {
            setIsWindowLoaded(true);
        };

        if (document.readyState === 'complete') {
            setIsWindowLoaded(true);
        } else {
            window.addEventListener('load', handleLoad);
        }

        return () => {
            window.removeEventListener('load', handleLoad);
        };
    }, []);

    return isWindowLoaded;
};
